import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CriteoEventType } from 'constants/criteo';
import useAuthResolver from 'hooks/useAuthResolver';
import { CriteoApiResponse } from 'types/Criteo';
import { getProductsFromResult } from 'utils/criteoUtil';
import { criteoKeys } from '.';
import { fetchSponsoredProducts } from './connector';

type UseSponsoredProductsParams = {
  enabled?: boolean;
  eventType: CriteoEventType;
  result?: CriteoApiResponse | null;
};

// FIXME: This hook shouldn't be receiving a complete api response but instead only the data needed. The whole fetchSponsoredProducts function does way too much also.
const useSponsoredProducts = ({ enabled = true, eventType, result }: UseSponsoredProductsParams) => {
  const { authResolved } = useAuthResolver();

  const productIds = result?.data
    ? getProductsFromResult(result.data, eventType).map((product) => product.ProductId)
    : [];

  return useQuery({
    enabled: authResolved && !!result && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchSponsoredProducts(eventType, result),
    queryKey: criteoKeys.sponsoredProducts(eventType, productIds),
  });
};

export { useSponsoredProducts };
