import { Product } from 'types/Product';
import { ProductSliderProps } from '../Product/ProductsSlider/ProductsSlider';
import SponsoredProductsCustomCampaignSlider from './SponsoredProductsCustomCampaignSlider';
import SponsoredProductsHomepageSlider from './SponsoredProductsHomepageSlider';
import SponsoredProductsPDPSlider from './SponsoredProductsPDPSlider';

export type SliderProps = Pick<ProductSliderProps, 'alwaysShowArrows' | 'slidesObject' | 'noMargin' | 'showSignings'>;

type CommonProps = {
  setHasProducts?: (value: boolean) => void;
  showTitle?: boolean;
};

interface SponsoredProductsHomeProps extends SliderProps, CommonProps {
  eventType: 'viewHome';
}

interface SponsoredProductsCustomCampaignProps extends SliderProps, CommonProps {
  campaignId?: string;
  category?: string;
  eventType: 'viewCustomCampaign';
  filters?: string;
}

interface SponsoredProductsPDPProps extends SliderProps, CommonProps {
  eventType: 'viewItem';
  product: Product;
}

type SponsoredProductsProps =
  | SponsoredProductsHomeProps
  | SponsoredProductsCustomCampaignProps
  | SponsoredProductsPDPProps;

const SponsoredProducts = ({ setHasProducts, showSignings = true, showTitle, ...props }: SponsoredProductsProps) => {
  const commonProps = { setHasProducts, showSignings, showTitle };
  const eventType = props.eventType;

  if (eventType === 'viewHome') {
    return <SponsoredProductsHomepageSlider {...commonProps} {...props} />;
  }
  if (eventType === 'viewItem') {
    return <SponsoredProductsPDPSlider {...commonProps} {...props} />;
  }
  if (eventType === 'viewCustomCampaign') {
    return <SponsoredProductsCustomCampaignSlider {...commonProps} {...props} />;
  }

  return null;
};

export default SponsoredProducts;
