import { useMutation } from '@tanstack/react-query';
import {
  pushViewCategoryEvent,
  pushViewCustomCampaignEvent,
  pushViewHomeEvent,
  pushViewItemEvent,
  pushViewSearchResult,
} from './connector';

const useMutatePushCategoryEvent = () =>
  useMutation({
    mutationFn: pushViewCategoryEvent,
  });

const useMutatePushCustomCampaignEvent = () =>
  useMutation({
    mutationFn: pushViewCustomCampaignEvent,
  });

const useMutatePushViewHomeEvent = () =>
  useMutation({
    mutationFn: pushViewHomeEvent,
  });

const useMutatePushViewItemEvent = () =>
  useMutation({
    mutationFn: pushViewItemEvent,
  });

const useMutatePushSearchResultsEvent = () =>
  useMutation({
    mutationFn: pushViewSearchResult,
  });

export {
  useMutatePushCategoryEvent,
  useMutatePushCustomCampaignEvent,
  useMutatePushSearchResultsEvent,
  useMutatePushViewHomeEvent,
  useMutatePushViewItemEvent,
};
