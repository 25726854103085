import ProductsSlider from 'components/Product/ProductsSlider/ProductsSlider';
import useSponsoredProductsForPDP from 'hooks/useSponsoredProductsForPDP';
import { useIntl } from 'react-intl';
import { Product } from 'types/Product';
import { SliderProps } from './SponsoredProducts';

interface SponsoredProductsPDPSliderProps extends SliderProps {
  product: Product;
  setHasProducts?: (value: boolean) => void;
  showSignings?: boolean;
  showTitle?: boolean;
}

const SponsoredProductsPDPSlider = ({
  product,
  setHasProducts,
  showSignings,
  showTitle,
  ...sliderProps
}: SponsoredProductsPDPSliderProps) => {
  const { formatMessage } = useIntl();

  const { onViewFormat, sponsoredProductsData } = useSponsoredProductsForPDP({
    product,
    setHasProducts,
  });

  const trackedProducts = sponsoredProductsData?.trackedProducts;

  if (!trackedProducts?.length) return null;

  return (
    <ProductsSlider
      isLoading={!sponsoredProductsData}
      onView={onViewFormat}
      products={trackedProducts.map((product) => ({ ...product, isSponsored: true }))}
      showSignings={showSignings}
      title={showTitle ? formatMessage({ id: 'sponsored_products_title' }) : undefined}
      {...sliderProps}
    />
  );
};
export default SponsoredProductsPDPSlider;
