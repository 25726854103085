import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { useMutatePushViewItemEvent } from 'features/criteo/mutations';
import { useSponsoredProducts } from 'features/criteo/queries';
import { useEffect, useRef } from 'react';
import { Product } from 'types/Product';
import { mapProductToCriteoFilters } from 'utils/criteoUtil';
import { log } from 'utils/loggerUtil';
import { canBeAddedToCart } from 'utils/productUtil';
import { useCriteoTracking } from './useCriteoTracking';
import usePrevious from './usePrevious';
import useRouter from './useRouter';

type Params = {
  product: Product;
  setHasProducts?: (value: boolean) => void;
};

const useSponsoredProductsForPDP = ({ product, setHasProducts }: Params) => {
  const criteoTrackingHasFired = useRef(false);
  const { asPath } = useRouter();
  const previousProduct = usePrevious(product);
  const productHasChanged = previousProduct?.name !== product?.name;

  const { data: criteoResult, mutate: pushViewItemEvent } = useMutatePushViewItemEvent();
  const { pushCriteoBeacon, userHasSufficientConsent } = useCriteoTracking();

  useEffect(() => {
    if (product?.price?.value && userHasSufficientConsent && (!criteoTrackingHasFired.current || productHasChanged)) {
      pushViewItemEvent({
        availability: !!canBeAddedToCart(product),
        filters: mapProductToCriteoFilters(product),
        item: product.code,
        listPrice: product?.strikePrice?.value || product.price.value,
        price: product.price.value,
      });
      criteoTrackingHasFired.current = true;
    }
  }, [product, userHasSufficientConsent]);

  const { data: sponsoredProductsData } = useSponsoredProducts({
    eventType: CRITEO_EVENT_TYPES.VIEW_ITEM,
    result: criteoResult,
  });

  const trackedProducts = sponsoredProductsData?.trackedProducts;
  const onLoadFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnLoadBeacon;
  const onViewFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnViewBeacon;

  useEffect(() => {
    if (trackedProducts?.length && pushCriteoBeacon) {
      if (onLoadFormatBeacon) {
        log(`Criteo - SP - ${asPath}`, 'pushing OnLoadBeacon for format', onLoadFormatBeacon);
        pushCriteoBeacon(onLoadFormatBeacon);
      }
      trackedProducts.forEach((product) => {
        if (product?.tracking?.OnLoadBeacon) {
          log(`Criteo - SP - ${asPath}`, `pushing OnLoadBeacon for product with code: ${product?.code}`);
          pushCriteoBeacon(product.tracking.OnLoadBeacon);
        }
      });
    }

    if (setHasProducts) {
      setHasProducts(!!trackedProducts?.length);
    }
  }, [sponsoredProductsData]);

  const onViewFormat = () => {
    if (pushCriteoBeacon && onViewFormatBeacon) {
      log(`Criteo - SP - ${asPath}`, 'pushing OnViewBeacon for format', onViewFormatBeacon);
      pushCriteoBeacon(onViewFormatBeacon);
    }
  };

  return { onViewFormat, sponsoredProductsData };
};
export default useSponsoredProductsForPDP;
