import { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import SponsoredProducts from 'components/SponsoredProducts/SponsoredProducts';
import { useState } from 'react';
import { IBlockSponsoredProducts } from 'types/ContentfulTypes';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';

type eventTypeMapType = {
  [key in IBlockSponsoredProducts['fields']['type']]: 'viewHome' | 'viewCustomCampaign';
};

const eventTypeMap: eventTypeMapType = {
  Custom: 'viewCustomCampaign',
  Home: 'viewHome',
};

interface ContentfulBlockSponsoredProductsProps extends ProductSliderProps {
  className?: string;
  content: IBlockSponsoredProducts;
  hideTitle?: boolean;
}

const ContentfulBlockSponsoredProducts = ({
  alwaysShowArrows = false,
  className,
  content,
  hideTitle,
  noMargin = true,
  slidesObject,
}: ContentfulBlockSponsoredProductsProps) => {
  const [hasProducts, setHasProducts] = useState(true);

  const { campaignId, category, filters, showSignings, title, type } = content.fields;

  const sliderProps = {
    alwaysShowArrows,
    noMargin,
    slidesObject,
  };

  const eventType = eventTypeMap[type];

  if (!content?.fields) {
    return null;
  }

  return (
    <ContentfulGroupingWrapper className={className}>
      {!hideTitle && hasProducts && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      <SponsoredProducts
        {...sliderProps}
        eventType={eventType}
        setHasProducts={setHasProducts}
        showSignings={showSignings}
        filters={filters}
        campaignId={campaignId}
        category={category}
      />
    </ContentfulGroupingWrapper>
  );
};
export default ContentfulBlockSponsoredProducts;
