import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { useMutatePushCustomCampaignEvent } from 'features/criteo/mutations';
import { useSponsoredProducts } from 'features/criteo/queries';
import useRouter from 'hooks/useRouter';
import { useEffect, useRef } from 'react';
import { log } from 'utils/loggerUtil';
import { useCriteoTracking } from './useCriteoTracking';

type Params = {
  campaignId?: string;
  category?: string;
  filters?: string;
  setHasProducts?: (value: boolean) => void;
};

const useSponsoredProductsForCustomCampaign = ({ campaignId, category, filters, setHasProducts }: Params) => {
  const criteoTrackingHasFired = useRef(false);
  const { asPath } = useRouter();

  const { data: criteoResult, mutate: pushViewCustomCampaignEvent } = useMutatePushCustomCampaignEvent();
  const { pushCriteoBeacon, userHasSufficientConsent } = useCriteoTracking();

  useEffect(() => {
    if (userHasSufficientConsent && !criteoTrackingHasFired.current) {
      pushViewCustomCampaignEvent({ campaignId, category, filters });
      criteoTrackingHasFired.current = true;
    }
  }, [userHasSufficientConsent]);

  const { data: sponsoredProductsData } = useSponsoredProducts({
    eventType: CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN,
    result: criteoResult,
  });

  const trackedProducts = sponsoredProductsData?.trackedProducts;
  const onLoadFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnLoadBeacon;
  const onViewFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnViewBeacon;

  useEffect(() => {
    if (trackedProducts?.length && pushCriteoBeacon) {
      if (onLoadFormatBeacon) {
        log(`Criteo - SP - ${asPath}`, 'pushing OnLoadBeacon for format', onLoadFormatBeacon);
        pushCriteoBeacon(onLoadFormatBeacon);
      }
      trackedProducts.forEach((product) => {
        if (product?.tracking?.OnLoadBeacon) {
          log(`Criteo - SP - ${asPath}`, `pushing OnLoadBeacon for product with code: ${product?.code}`);
          pushCriteoBeacon(product.tracking.OnLoadBeacon);
        }
      });
    }

    if (setHasProducts) {
      setHasProducts(!!trackedProducts?.length);
    }
  }, [sponsoredProductsData]);

  const onViewFormat = () => {
    if (pushCriteoBeacon && onViewFormatBeacon) {
      log(`Criteo - SP - ${asPath}`, 'pushing OnViewBeacon for format', onViewFormatBeacon);
      pushCriteoBeacon(onViewFormatBeacon);
    }
  };

  return { onViewFormat, sponsoredProductsData };
};
export default useSponsoredProductsForCustomCampaign;
