import ProductsSlider from 'components/Product/ProductsSlider/ProductsSlider';
import useSponsoredProductsForCustomCampaign from 'hooks/useSponsoredProductsForCustomCampaign';
import { useIntl } from 'react-intl';
import { SliderProps } from './SponsoredProducts';

interface SponsoredProductsCustomCampaignSliderProps extends SliderProps {
  campaignId?: string;
  category?: string;
  filters?: string;
  setHasProducts?: (value: boolean) => void;
  showSignings?: boolean;
  showTitle?: boolean;
}

const SponsoredProductsCustomCampaignSlider = ({
  campaignId,
  category,
  filters,
  setHasProducts,
  showSignings,
  showTitle,
  ...sliderProps
}: SponsoredProductsCustomCampaignSliderProps) => {
  const { formatMessage } = useIntl();

  const { onViewFormat, sponsoredProductsData } = useSponsoredProductsForCustomCampaign({
    campaignId,
    category,
    filters,
    setHasProducts,
  });

  const trackedProducts = sponsoredProductsData?.trackedProducts;

  if (!trackedProducts?.length) return null;

  return (
    <ProductsSlider
      isLoading={!sponsoredProductsData}
      onView={onViewFormat}
      products={trackedProducts.map((product) => ({ ...product, isSponsored: true }))}
      showSignings={showSignings}
      title={showTitle ? formatMessage({ id: 'sponsored_products_title' }) : undefined}
      {...sliderProps}
    />
  );
};
export default SponsoredProductsCustomCampaignSlider;
