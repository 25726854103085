import ProductsSlider from 'components/Product/ProductsSlider/ProductsSlider';
import useSponsoredProductsForHomepage from 'hooks/useSponsoredProductsForHomepage';
import { useIntl } from 'react-intl';
import { SliderProps } from './SponsoredProducts';

interface SponsoredProductsHomepageSliderProps extends SliderProps {
  setHasProducts?: (value: boolean) => void;
  showSignings?: boolean;
  showTitle?: boolean;
}

const SponsoredProductsHomepageSlider = ({
  setHasProducts,
  showSignings,
  showTitle,
  ...sliderProps
}: SponsoredProductsHomepageSliderProps) => {
  const { formatMessage } = useIntl();

  const { onViewFormat, sponsoredProductsData } = useSponsoredProductsForHomepage({ setHasProducts });

  const trackedProducts = sponsoredProductsData?.trackedProducts;

  if (!trackedProducts?.length) return null;

  return (
    <ProductsSlider
      isLoading={!sponsoredProductsData}
      onView={onViewFormat}
      products={trackedProducts.map((product) => ({ ...product, isSponsored: true }))}
      showSignings={showSignings}
      title={showTitle ? formatMessage({ id: 'sponsored_products_title' }) : undefined}
      {...sliderProps}
    />
  );
};
export default SponsoredProductsHomepageSlider;
