import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { useMutatePushViewHomeEvent } from 'features/criteo/mutations';
import { useSponsoredProducts } from 'features/criteo/queries';
import { useEffect, useRef } from 'react';
import { log } from 'utils/loggerUtil';
import { useCriteoTracking } from './useCriteoTracking';

type Params = {
  setHasProducts?: (value: boolean) => void;
};

const useSponsoredProductsForHomepage = ({ setHasProducts }: Params) => {
  const criteoTrackingHasFired = useRef(false);

  const { data: criteoResult, mutate: pushViewHomeEvent } = useMutatePushViewHomeEvent();
  const { pushCriteoBeacon, userHasSufficientConsent } = useCriteoTracking();

  useEffect(() => {
    if (userHasSufficientConsent && !criteoTrackingHasFired.current) {
      pushViewHomeEvent();
      criteoTrackingHasFired.current = true;
    }
  }, [userHasSufficientConsent]);

  const { data: sponsoredProductsData } = useSponsoredProducts({
    eventType: CRITEO_EVENT_TYPES.VIEW_HOME,
    result: criteoResult,
  });

  const trackedProducts = sponsoredProductsData?.trackedProducts;
  const onLoadFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnLoadBeacon;
  const onViewFormatBeacon = sponsoredProductsData?.placementTracking[0]?.OnViewBeacon;

  useEffect(() => {
    if (trackedProducts?.length && pushCriteoBeacon) {
      if (onLoadFormatBeacon) {
        log('Criteo - SP - Homepage', 'pushing OnLoadBeacon for format', onLoadFormatBeacon);
        pushCriteoBeacon(onLoadFormatBeacon);
      }
      trackedProducts.forEach((product) => {
        if (product?.tracking?.OnLoadBeacon) {
          log('Criteo - SP - Homepage', `pushing OnLoadBeacon for product with code: ${product?.code}`);
          pushCriteoBeacon(product.tracking.OnLoadBeacon);
        }
      });
    }

    if (setHasProducts) {
      setHasProducts(!!trackedProducts?.length);
    }
  }, [sponsoredProductsData]);

  const onViewFormat = () => {
    if (pushCriteoBeacon && onViewFormatBeacon) {
      log('Criteo - SP - Homepage', 'pushing OnViewBeacon for format', onViewFormatBeacon);
      pushCriteoBeacon(onViewFormatBeacon);
    }
  };

  return { onViewFormat, sponsoredProductsData };
};
export default useSponsoredProductsForHomepage;
